<template>
	<div class="ui grid p-0 m-0">
		<div class="row p-0 m-0">
			<sidebar/>
			<div class="thirteen wide column p-0">
				<navbar/>

				<!-- page-content -->
				<div class="ui stackable grid m-0">
					<div class="ui row">
						<div class="wide column">

							<!-- header -->
							<div class="row padded">
								<div class="column">
									<h3 class="ui dividing padded header app-blue">
										Recherche
									</h3>
								</div>
							</div>

							<div class="row" style="margin-top: 25px;">
								<div class="column">
									<div class="ui form" @keypress.enter="search">
										<div class="inline fields">
											<div class="field">
												<i class="search icon"></i>
											</div>
											<div class="thirteen wide field">
												<div class="ui action input">
													<input type="text" placeholder="Recherche ..." v-model="search_query">
													<button class="ui teal icon button" @click="search">
														<i class="search icon"></i>
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!--<div class="row" style="margin-top: 25px;">
								<div class="ui form" @keypress.enter="getFolders">
									<div class="two fields">
										<div class="field">
											<input type="text" class="placeholder-color" placeholder="Réf"
												   v-model="search_fields.numero">
										</div>
										<div class="field">
											<input type="text" class="placeholder-color" placeholder="Réfécence Cie"
												   v-model="search_fields.reference_cie"/>
										</div>
									</div>

									<div class="four fields">
										<div class="field">
											<input type="text" class="placeholder-color"
												   placeholder="Nom de l'assuré" v-model="search_fields.nom_assure">
										</div>
										<div class="field">
											<input type="text" class="placeholder-color"
												   placeholder="Prénom de l'assuré"
												   v-model="search_fields.prenom_assure">
										</div>
										<div class="field">
											<v-select :options="marque_list" placeholder="Véh"
													  class="placeholder-color"
													  v-model="search_fields.marque_assure" @input="getModelList"/>
										</div>
										<div class="field">
											<v-select :options="model_list" class="placeholder-color"
													  placeholder="Type" v-model="search_fields.modele_assure"/>
										</div>
									</div>

									<div class="four fields">
										<div class="field">
											<input type="text" class="placeholder-color"
												   placeholder="Nom de l'adverse"
												   v-model="search_fields.nom_adverse">
										</div>
										<div class="field">
											<input type="text" class="placeholder-color"
												   placeholder="Prénom de l'adverse"
												   v-model="search_fields.prenom_adverse">
										</div>
										<div class="field">
											<input type="text" class="placeholder-color"
												   placeholder="IMM de l'adverse"
												   v-model="search_fields.immat_adverse">
										</div>
										<div class="field">
											<input type="text" class="placeholder-color"
												   placeholder="IMM de l'assuré"
												   v-model="search_fields.immat_assure">
										</div>
									</div>

									&lt;!&ndash;                                        <div class="inline fields">&ndash;&gt;
									&lt;!&ndash;<div class="field">
                                        <i class="search icon"></i>
                                    </div>&ndash;&gt;
									&lt;!&ndash;<div class="sixteen wide field">

                                        <input type="text" placeholder="Search Data Base..." v-model.trim="search" @keyup.enter="getFolders">
                                    </div>&ndash;&gt;
									&lt;!&ndash;                                            <div class="field">&ndash;&gt;
									<div style="margin: 10px 0">
										<button class="ui positive right labeled icon button"
												@click.prevent="search" style="border-radius: 6px;">
											<i class="search icon"></i>
											Chercher
										</button>
										&lt;!&ndash;                                            </div>&ndash;&gt;

										<button class="ui right floated button" @click="clearFields"
												style="border-radius: 6px;">
											Re-initialiser
										</button>
									</div>
									<div style="clear: both"></div>
									&lt;!&ndash;                                        </div>&ndash;&gt;


								</div>
							</div>-->

							<!--<div class="row">

								<div class="ui accordion">
									<div class="title" style="font-size: 15px">
										<i class="dropdown icon"></i>
										<u>Recherche avancée</u>
									</div>
									<div class="content">

										<div class="ui form">

											<div class="two fields">
												<div class="field">
													<v-select label="label" :options="assurance_list"
															  placeholder="Compagnie"
															  v-model="search_fields.assurance"
															  class="placeholder-color">
													</v-select>
												</div>
												<div class="field">
													<v-select label="label" :options="courtier_list"
															  placeholder="Agent/Courtier"
															  v-model="search_fields.courtier"
															  class="placeholder-color">
													</v-select>
												</div>
											</div>

											<div class="three fields">

												<div class="field">
													<v-select placeholder="Type dossier" :reduce="type => type._id"
															  :options="type_dossier_options"
															  class="placeholder-color"
															  v-model="search_fields.type_dossier"/>
												</div>

												&lt;!&ndash;<div class="field">
													<v-select placeholder="Status" :reduce="status => status._id"
															  class="placeholder-color"
															  :options="status_options"
															  v-model="search_fields.dossier_status"/>
												</div>&ndash;&gt;

												<div class="field">
													<dateMilliRange class="custom-date placeholder-color" :key="1"
																	:label="'Date de Mission'"
																	v-model="date_creation"/>
												</div>

												<div class="field">
													<dateMilliRange class="custom-date placeholder-color" :key="2"
																	:label="'Date sinistre'"
																	v-model="date_sinistre"/>
												</div>

											</div>

										</div>

									</div>
								</div>

								<div class="ui divider" style="margin-top:0"></div>
								&lt;!&ndash;<advancedSearch></advancedSearch>&ndash;&gt;
							</div>-->

							<!-- form -->
							<!--<div class="ui form" @keypress.enter="search">
								<div class="field">
									<input type="text" class="placeholder-color" placeholder="Réf"
									       v-model="numero">
								</div>
								&lt;!&ndash; actions &ndash;&gt;
								<div class="field" style="margin: 10px 0">
									<button class="ui positive right labeled icon button"
									        @click.prevent="search" style="border-radius: 6px;">
										<i class="search icon"></i>
										Chercher
									</button>
									<button class="ui right floated button" @click="clearFields"
									        style="border-radius: 6px;">
										Re-initialiser
									</button>
								</div>
							</div>-->

							<!-- table, search_result -->
							<table class="ui selectable celled table">
								<thead>
								<tr>
									<th style="width: auto;">Numero</th>
									<th style="width: auto;">Assuré</th>
									<th style="width: auto;">VH</th>
									<th style="width: auto;">Imm</th>
									<th style="width: auto;">D.sinistre</th>
									<th style="width: auto;">Agent/courtier</th>
									<th style="width: auto;">Type dossier</th>
									<th style="width: auto;">Total Net</th>
									<th></th>
								</tr>
								</thead>
								<tbody>
								<tr v-if="!search_result.length">
									<td colspan="99">Pas de données disponibles</td>
								</tr>
								<tr v-for="dossier in search_result">
									<td class="danger-cell"> {{ dossier.numero || dash}}</td>
									<td>{{dossier.propri ? (dossier.propri.nom || dash) + ' '+
										(dossier.propri.prenom || dash) : dash }}
									</td>
									<td>{{(dossier.vehicule && dossier.vehicule.marque) ?
										dossier.vehicule.marque.label || dash : dash}}
									</td>
									<td> {{ dossier.vehicule ? (dossier.vehicule.n_immat || dash) : dash}}</td>
									<td>{{dossier.assurance.date_sinistre ?
										$store.getDate(dossier.assurance.date_sinistre, "DD/MM/YYYY") || dash :
										dash}}
									</td>
									<td>{{dossier.courtier ? dossier.courtier.label || dash : dash}}</td>
									<td> {{ dossier.type_dossier || dash}}</td>
									<td> {{ dossier.total_net}}</td>
									<td class="collapsing">
										<div @click="selectDossier(dossier)" style="cursor: pointer">
											<i class="ui add icon"></i>
										</div>
									</td>
								</tr>
								</tbody>
								<tfoot>
								<tr>
									<th colspan="99">
										<div>
											<pagination :limit="limit" :current_page="page" :count="count"
											            @setPage="setPage" slot="pagination"/>
										</div>
									</th>
								</tr>
								</tfoot>
							</table>

							<div class="ui header">Les dossiers sélectionnés</div>

							<!-- table, selected_dossiers -->

							<table class="ui selectable celled table">
								<thead>
								<tr>
									<th style="width: auto;">Numero</th>
									<th style="width: auto;">Assuré</th>
									<th style="width: auto;">VH</th>
									<th style="width: auto;">Imm</th>
									<th style="width: auto;">D.sinistre</th>
									<th style="width: auto;">Agent/courtier</th>
									<th style="width: auto;">Type dossier</th>
									<th style="width: auto;">Total Net</th>
								</tr>
								</thead>
								<tbody>
								<tr v-if="!selected_dossiers.length">
									<td colspan="99">Aucun dossier n'est sélectionné</td>
								</tr>
								<tr v-for="dossier in selected_dossiers">
									<td class="danger-cell"> {{ dossier.numero || dash}}</td>
									<td>{{dossier.propri ? (dossier.propri.nom || dash) + ' '+
										(dossier.propri.prenom || dash) : dash }}
									</td>
									<td>{{(dossier.vehicule && dossier.vehicule.marque) ?
										dossier.vehicule.marque.label || dash : dash}}
									</td>
									<td> {{ dossier.vehicule ? (dossier.vehicule.n_immat || dash) : dash}}</td>
									<td>{{dossier.assurance.date_sinistre ?
										$store.getDate(dossier.assurance.date_sinistre, "DD/MM/YYYY") || dash :
										dash}}
									</td>
									<td>{{dossier.courtier ? dossier.courtier.label || dash : dash}}</td>
									<td> {{ dossier.type_dossier || dash}}</td>
									<td> {{ dossier.total_net}}</td>
								</tr>
								</tbody>
							</table>

							<button class="ui primary right labeled right floated icon button"
							        @click="showPaymentModal" style="border-radius: 6px;">
								<i class="check icon"></i>
								Payer
							</button>

							<!-- payment modal -->
							<modal :clickToClose="true" name="payment-modal" height="auto">
								<div class="ui container p-1">
									<div class="ui form" @keypress.enter="payer">
										<div class="field">
											<input type="datetime-local" placeholder="Réf"
											       v-model="date_paiement">
										</div>
										<div class="field">
											<input type="text" class="placeholder-color" placeholder="Libelle"
											       v-model="paiement_input">
										</div>
										<!-- actions -->
										<div class="field" style="margin: 10px 0">
											<button class="ui positive button" @click.prevent="payer"
											        style="border-radius: 6px;">
												Payer
											</button>
											<button class="ui right floated button"
											        @click="$modal.hide('payment-modal')"
											        style="border-radius: 6px;">
												Annuler
											</button>
										</div>
									</div>
								</div>
							</modal>

						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import sidebar from "../components/sidebar";
	import navbar from '@/components/navbar.vue';
	import pagination from "@/components/pagination.vue";
	import dateMilliRange from '@/components/search/dateMilliRange'

	export default {
		name: "recherche",
		components: {sidebar, navbar, pagination, dateMilliRange},
		data() {
			return {
				numero: '',
				date_paiement: new Date(),
				paiement_input: '',
				page: 1,
				limit: 10,
				search_result: [],
				count: 0,
				selected_dossiers: [],
				dash: '--------',
				type_dossier_options: [
					{_id: 'reforme', label: 'Reforme'},
					{_id: 'reparation', label: 'Réparation'}
				],
				status_options: [
					{_id: 'active', label: 'Active'},
					{_id: 'cloturer', label: 'Clôturé'},
					{_id: 'paye', label: 'Payé'},
					{_id: 'suspended', label: 'Suspendu'},
				],
				//--------------
				search_query: '',
				search_fields: {},
				assurance_list: [],
				courtier_list: [],
				marque_list: [],
				model_list: [],
				date_creation: null,
				date_sinistre: null
			}
		},
		methods: {
			search() {
				log('search');
				let search_query = this.searchQuery();
				const data = {
					collection: this.$SHARED.collections.documents,
					query: {
						type: 'dossier', status: 'cloturer',
						...search_query
					},
					options: {
						page: parseInt(this.page),
						limit: parseInt(this.limit)
					}
				};
				this.$store.requestFind(data).then(ar => {
					this.search_result = ar.docs;
					this.count = ar.count;
				}, code => {
					this.$notify({
						group: 'user-message', type: 'error',
						text: this.$SHARED.messages[code]
					});
				})
			},
			searchQuery() {
				console.log("searchQuery");
				let text_query = []
				let dates_query = []
				if(this.search_query){
                    const text_fields= [
                        {field:'numero', caseIns:true},
                        {field:'vehicule.n_immat', caseIns:false},
                        {field: 'propri.nom', caseIns:true},
                        {field: 'propri.prenom', caseIns:true},
                        {field: 'propri.tel'},
                        {field: 'adverse.nom', caseIns:true},
                        {field: 'adverse.prenom', caseIns:true},
                        {field: 'adverse.tel', caseIns:true},
                        {field:'vehicule_adverse.n_immat', caseIns:false},
                        {field:'reference_cie', caseIns:true},
                    ];
                    text_fields.forEach(field_elm => {
                        const field_toadd={[field_elm.field]: {$regex: this.search_query}}
                        if(field_elm.caseIns){
                            field_toadd[field_elm.field].$options = 'i'
                        }
                        text_query.push(field_toadd);
                    })
                }

				/*if(this.dates){
                    const dates_fields = [
                        'date_creation',
                        'assurance.date_sinistre',
                        'assurance.date_validation',
                        'vehicule.dmc',
                        'adverse.vehicule.dmc'
                    ]
                    let field_query = {};
                    if(this.dates.start)
                        field_query.$gt = this.dates.start;
                    if(this.dates.end)
                        field_query.$lt = this.dates.end;

                    dates_fields.forEach(field_elm =>{
                        dates_query.push({[field_elm]:field_query})
                    })
                }*/

				/*if (this.date_creation) {
					dates_query.push({date_creation: this.dateToQuery(this.date_creation)})
				}

				if (this.date_sinistre) {
					dates_query.push({"assurance.date_sinistre": this.dateToQuery(this.date_sinistre)})
				}

				if (this.dmc) {
					dates_query.push({"vehicule.dmc": this.dateToQuery(this.dmc)})
					/!*dates_query.push({"adverse.vehicule.dmc": this.dateToQuery(this.dmc)})*!/
				}*/

				let query = {}
				if (dates_query.length && text_query.length) {
					query.$and = [{$or: dates_query}, {$or: text_query}]
				} else if (text_query.length) {
					query.$or = text_query;
				} else if (dates_query.length) {
					query.$or = dates_query;
				}

				/*if (this.search_fields.numero) {
					query["numero"] = {$regex: this.search_fields.numero, $options: 'i'};
				}

				if (this.search_fields.reference_cie) {
					query["reference_cie"] = {$regex: this.search_fields.reference_cie, $options: 'i'};
				}

				if (this.search_fields.prenom_assure) {
					query["propri.prenom"] = {$regex: this.search_fields.prenom_assure, $options: 'i'};
				}

				if (this.search_fields.nom_assure) {
					query["propri.nom"] = {$regex: this.search_fields.nom_assure, $options: 'i'};
				}


				if (this.search_fields.nom_adverse) {
					query["adverse.nom"] = {$regex: this.search_fields.nom_adverse, $options: 'i'};
				}

				if (this.search_fields.prenom_adverse) {
					query["adverse.prenom"] = {$regex: this.search_fields.prenom_adverse, $options: 'i'};
				}

				if (this.search_fields.marque_assure) {
					query["vehicule.marque._id"] = {$regex: this.search_fields.marque_assure._id, $options: 'i'};
				}

				if (this.search_fields.modele_assure) {
					query["vehicule.model._id"] = {$regex: this.search_fields.modele_assure._id, $options: 'i'};
				}

				if (this.search_fields.immat_adverse) {
					query["adverse.vehicule.n_immat"] = {$regex: this.search_fields.immat_adverse, $options: 'i'};
				}

				if (this.search_fields.immat_assure) {
					query["vehicule.n_immat"] = {$regex: this.search_fields.immat_assure, $options: 'i'};
				}

				if (this.search_fields.type_dossier) {
					query.type_dossier = this.search_fields.type_dossier;
				}
				if (this.search_fields.assurance) {
					query['assurance._id'] = this.search_fields.assurance._id
				}
				if (this.search_fields.courtier) {
					query['courtier._id'] = this.search_fields.courtier._id
				}*/
				console.log("QUERY", query);
				return query;
			},
			setPage(page) {
				this.page = page;
				this.search();
			},
			selectDossier(dossier) {
				log('selectDossier');
				const index = _.findIndex(this.selected_dossiers, {numero: dossier.numero})
				_.remove(this.search_result, {_id: dossier._id})
				if (index === -1)
					this.selected_dossiers.unshift(dossier)
			},
			clearFields() {
				this.search_fields = {};
				this.date_creation = null;
				this.date_sinistre = null;
				this.search()
			},
			showPaymentModal() {
				log('showPaymentModal')
				if (!this.selected_dossiers.length)
					return;
				// init payment
				this.date_paiement = '';
				this.paiement_input = '';
				this.$modal.show('payment-modal');
			},
			payer() {
				log('payer')
				if (!this.selected_dossiers.length)
					return;
				const data = {
					collection: this.$SHARED.collections.documents,
					query: {_id: {$in: this.selected_dossiers.map(e => e._id)}},
					update: {'$set': {
							date_paiement: new Date(this.date_paiement).getTime(),
							paiement_input: this.paiement_input,
							status: 'paye'
					}},
					options:{multi: true}
				};
				this.$store.requestUpdate(data).then(() => {
					this.selected_dossiers.splice(0)
					this.search_result.splice(0)
					this.clearFields();

					this.$modal.hide('payment-modal');
					this.$notify({
						group: 'user-message',
						type: 'success', // warn , error, success, info
						text: this.$SHARED.messages.document.update_succeeded
					});
				}, code => {
					this.$notify({
						group: 'user-message',
						type: 'error', // warn , error, success, info
						text: this.$SHARED.messages[code]
					});
				});
			},
			getAssurancesList() {
				log('getAssurancesList');
				const data = {
					collection: this.$SHARED.collections.assurance,
					query: {},
					options: {page: 1, limit: -1, sort: {label: 1}}
				};
				this.$store.requestFind(data).then(ar => {
					this.assurance_list = ar.docs;
				}, code => {
					this.$notify({
						group: 'user-message', type: 'error',
						text: this.$SHARED.messages.technical_error
					});
				})
			},
			getCourtierList() {
				log('getCourtierList');
				const data = {
					collection: this.$SHARED.collections.courtier,
					query: {},
					options: {page: 1, limit: -1, sort: {label: 1}}
				};
				this.$store.requestFind(data).then(ar => {
					this.courtier_list = ar.docs;
				}, code => {
					this.$notify({
						group: 'user-message', type: 'error',
						text: this.$SHARED.messages.technical_error
					});
				})
			},
			getMarqueList() {
				log('getMarques');
				const data = {
					collection: this.$SHARED.collections.vehicule,
					query: {type: 'marque'},
					options: {page: 1, limit: -1, sort: {label: 1}}
				};
				this.$store.requestFind(data).then(ar => {
					this.marque_list = ar.docs;
				}, code => {
					this.$notify({
						group: 'user-message', type: 'error',
						text: this.$SHARED.messages.technical_error
					});
				})
			},
			getModelList() {
				log('getModels');
				if (!this.search_fields.marque_assure)
					return;

				const data = {
					collection: this.$SHARED.collections.vehicule,
					query: {type: 'model', id_pere: this.search_fields.marque_assure._id},
					options: {page: 1, limit: -1, sort: {label: 1}}
				};
				this.$store.requestFind(data).then(ar => {
					this.model_list = ar.docs;
				}, code => {
					this.$notify({group: 'user-message', type: 'error', text: this.$SHARED.messages.technical_error});
				})
			},
		},
		mounted() {
			$('.ui.accordion').accordion({exclusive: true});
			this.search();
			this.getAssurancesList();
			this.getCourtierList();
			this.getMarqueList();
			this.getModelList();
		}
	}
</script>

<style scoped>

</style>